.App {
  text-align: center;
}

/* .navbar {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.main-bar {
  background-color: green;
}

.login-btn {
  width: 15%;
}
.search-input {
  color: white;
  background-color: #ececec;
  padding: 5px;
  border-radius: 14px;
}

.icon-button {
  color: white;
}
.logo-container {
  height: 50px;
  width: 100px;
  margin: 0;
  cursor: pointer;
}

.login-container {
  display: flex;
  justify-content: start;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  color: white;
  background-image: url("../public/background.svg");
  padding-bottom: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
}

img {
  width: 400px;
  margin: 50px;
}

.input-textfield .MuiInputBase-root {
  border-radius: 14px;
  color: white;
}

.input-form {
  margin-top: 40px !important;
  width: 100%;
  align-items: center;
}
.login-form-container {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}
.main-container {
  min-height: 100vh;
  background-color: white;
}
.main-container1 {
  min-height: 80vh;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.meal-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top: 50px;
  height: 100vh;
  border-radius: 14px;
  width: min-content;
}
.meal-details-header-color {
  color: #80c522;
}
.back-ground-shadow {
  background-color: #d9d9d9;
  display: flex;
  width: 100% !important;
  font-weight: bold;
}

.survey-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-top: 50px;
  width: 50%;
  border-radius: 14px;
  min-height: 70vh;
}
.continue-survey-btn-container {
  display: flex;
  height: 100%;
  align-items: end;
}
.radio-conditions {
  cursor: pointer;
}

.button-container {
  width: 100%;
  background-size: cover;
  margin-top: 50px;
  background-repeat: no-repeat;
  margin-bottom: 100px;
}
.icon-img {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}
.goals-text-container {
  margin: 0;
}
.icon-sex-img {
  width: 30px;
  height: 50px;
}
.green-text {
  color: #80c522 !important;
  background-color: white !important;
}
.custom-tooltip {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  padding: 1rem;
  border-radius: 14px;
}
.label-tooltip {
  color: rgb(13, 255, 231);
}

.green-text .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  background-color: white !important;
}
.card-component {
  width: 50%;
  padding: 10px;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #ececec !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card-sex-component {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #ececec !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
.start-use-btn {
  margin-top: 100px;
}
.logo-img-small {
  width: 200px;
  height: 100px;
  margin: 0;
}
.cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cards-container.sex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.card-select {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.card-sex-select {
  display: flex;
  height: 100%;
  width: 100px;
  flex-direction: column;
  justify-content: center; /* Centers items on the main axis (horizontal by default) */
  align-items: center; /* Centers items on the cross axis (vertical by default) */
}
.card-text-container {
  width: 100%;
  display: flex;
  justify-content: start;
}

.completed-title-img {
  width: 150px;
  height: 150px;
}

.img-button {
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.success-text {
  color: rgb(23, 187, 46);
}

.status-card-container {
  background-color: rgb(209, 241, 213);
  border-radius: 14px;
  width: 30%;
  padding: 20px;
  text-align: center;
}

.range-container {
  border-radius: 14px;
  background-color: #3b5b3f;
  color: white;
  font-size: x-small;
}
.horizontal-seperator {
  margin: 0;
  width: 90%;
  height: 1px;
  background-color: #13322b21;
}

.star-img {
  width: 25px;
  height: 23px;
  margin: 0;
}
.small-font-meal-card {
  font-size: x-small;
}

.vertical-seperator {
  margin: 5px;
  width: 1px;
  height: 20px;
  background-color: #13322b21;
}
.main-card-container {
  background-color: #3b5b3f70;
  border-radius: 14px;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.card-angle {
  width: 166px;
  height: 297.86px;
}
.card-meal-list {
  width: 284px;
  height: 428px;
}

.circle-popup {
  border-radius: 50%;
  background-color: #80c522;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.meal-card-top-container {
  perspective: 800px;
  width: 166px;
  height: 297.86px;
}

.meal-info-status-container {
  width: 450px;
  height: 366px;
  background-color: #f2f2f2;
  border: 5px solid #0000000d;
}
.number-progress-bar-status {
  color: #999999;
}
.dot {
  color: #9fdc1c;
  font-weight: bold;
}
.header-meal-info-status {
  color: #80c522;
  font-size: 20px;
  font-weight: bold;
}
.meal-card-container {
  background-color: rgb(245, 246, 246);
  border-radius: 14px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.small-font-meal-card-rate {
  color: #ffb30e;
}

.meal-main-card-container {
  background-color: rgb(245, 246, 246);
  border-radius: 14px;
  padding: 20px;
  text-align: center;
}

.meal-img {
  height: 200px;
  border-radius: 14px;
}
.meal-details-section {
  background-color: rgb(242, 242, 242);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-radius: var(--bs-border-radius) !important;
  margin-right: 3rem;
}
.bottom-section {
  z-index: 1;
  height: auto !important;
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.food-details-img {
  position: relative; /* Required for correct layering */
  overflow: hidden;
  min-height: 400px !important;
}
.food-details-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Set height to 50% for half-way fade */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgb(242, 242, 242) 100%
  );
  /* Adjust opacity values for desired fade effect */
}
.img-title-food-details {
  position: absolute; /* Allow absolute positioning */
  bottom: 0%; /* Center content vertically */
  left: 0; /* Align content to the left */
  right: 0; /* Stretch content to fill width */
  background-color: transparent; /* Remove any background color */
  z-index: 1; /* Ensure content is on top of the gradient */
  /* Add padding or margin for spacing between content and gradient edges */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.main-card-right-container {
  width: 364px;
}
.text-main-status-calories {
  font-size: 45.93px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
.rotate-progress-bra {
  transform: rotate(180deg);
}
.text-main-status-container {
  font-size: 22.4px;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.circle-progress-text {
  font-weight: 500 !important;
  font-size: 30px !important;
}
.main-card-left-container {
  width: 396px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  justify-content: space-between;
}
.main-container-header {
  margin-top: 50px;
  margin-bottom: 70px;
}
.bottom-main-container {
  background-color: #17bb2e4d;
  width: 100%;
}
.small-font {
  font-size: x-small;
}
.percentage-card-container {
  background-color: #e9e9e9;
  border-radius: 14px;
  padding: 20px;
  height: 120px;
  width: 120px;
  text-align: center;
}
.empty-card {
  width: 30%;
}
.weight-card-container {
  background-color: rgb(209, 241, 213);
  border-radius: 14px;
  width: 13%;
  padding: 20px;
  text-align: center;
}

.img-calory-burn {
  background-color: rgb(23, 187, 46);
  padding: 3px;
  width: 40px;
  height: 40px;
  border-radius: 14px;
  margin: 0;
}
.img-calory-burn-meal-list {
  background-color: rgb(23, 187, 46);
  padding: 3px;
  width: 64px;
  height: 64px;
  border-radius: 14px;
  margin: 0;
}
.img-calory-burn-main {
  background-color: rgb(23, 187, 46);
  padding: 3px;
  width: 89.94px;
  height: 89.94px;
  border-radius: 14px;
  margin: 0;
}
.disc-text-tag {
  background-color: rgb(23, 187, 46);
  border-radius: 14px;
}
.chip-chalories-container {
  position: relative;
}
.chip-meal-list {
  width: 56px;
  height: 42px;
}
.chip-chalories-inside {
  position: absolute;
  top: 10%;
  right: 10%;
}
.img-calory-burn-meal {
  background-color: rgb(23, 187, 46);
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgb(23, 187, 46);
  background-color: white;
  outline: none;
}

input[type="radio"]:checked {
  background-color: rgb(23, 187, 46);
}

.icon-with-background {
  margin: 0;
  width: 30px;
  height: 30px;
}

.menu-icon {
  margin: 5px;
  margin-right: 0px;
  width: 20px;
  height: 20px;
}

.main-icon {
  margin: 0;
  width: 50px;
  height: 70px;
}

.img-meal-small {
  margin: 0;
  width: 150px;
  height: 131.68px;
  border-radius: 14px;
}
.img-meal-small-list {
  margin: 0;
  width: 260px;
  height: 225px;
  border-radius: 14px;
}
.img-meal-list {
  width: 260px;
  height: 225px;
}
.arrow-img {
  margin: 0;
  width: 25px;
  height: 25px;
}
.recharts-layer .recharts-cartesian-axis .recharts-yAxis .yAxis {
  margin-left: 100px !important;
}

.card-component:hover {
  background-color: rgb(209, 241, 213) !important;
}

.arrow-img:hover {
  cursor: pointer;
}
.card-component.selected {
  background-color: rgb(209, 241, 213) !important;
}
.card-sex-component:hover {
  background-color: rgb(209, 241, 213) !important;
}

.card-sex-component.selected {
  background-color: rgb(209, 241, 213) !important;
}

.footer-container {
  background-color: #1a3129;
  color: #f2f2f2;
}

.logo-footer {
  width: 80px;
  height: 35px;
  margin: 10px;
}
.daily-meals-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.single-chosen-meal-main {
  margin-left: 3rem;
}

.footer-info-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  border: 1px solid #2c5446;
  padding: 5px;
  height: 52px;
  border-radius: 6px;
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #0000001a;
}

.icon-footer {
  width: 21px;
  height: 16.5px;
  margin: 3px;
}
.icon-nav {
  width: 25px;
  height: 25px;
}
.footer-info-all-container {
  width: 530px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body-nav-container {
  color: #13322b;
  font-size: 16px;
}
.header-nav-container {
  color: #054162;
  font-size: 12px;
  font-weight: 300;
}

.main-container-statistics {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.right-statistics-container {
  display: flex;
  flex-direction: row;
}
.main-category-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
.footer-bottom-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.info-body {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
}
.meal-info-details-table-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}
.meal-info-details-table-right {
  width: 25%;
}
.meal-info-details-table-left {
  width: 100%;
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
.meal-info-calories-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 50%;
  margin-top: 3rem;
}
.header-meal-info-calories-container {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 500;
}
.flip-icon {
  transform: rotate(180deg);
}
.discover-meal-card {
  margin-bottom: 3rem !important;
  margin-right: 3rem !important;
  height: 100% !important;
}
.mail-tag {
  text-decoration: none;
  color: white;
}
.discover-all-meal-cards-container {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem;
}
.email-text-field .MuiInputBase-root {
  background-color: white;
}
.active-color {
  color: #80c522;
}
.danger-color {
  color: #ff0000;
}
.social-media-container {
  display: flex;
  width: 5%;
  flex-direction: row;
  justify-content: space-around;
}

.login-field .MuiTypography-root {
  color: white !important;
}
@media screen and (max-width: 800px) {
  .card-component {
    width: 100% !important;
  }
  .login-btn {
    width: 60%;
  }
  .logo-img {
    width: 150px;
    height: 75px;
  }

  .cards-container.sex {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .survey-container {
    width: auto !important;
    padding: 2rem;
  }
  .info-body {
    width: 100%;
  }
  .meal-info-details-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .meal-info-details-table-right {
    width: 100%;
  }
  .meal-info-details-table-left {
    align-items: start !important;
    border-right: none !important;
    padding-right: 0;
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .meal-info-calories-container {
    width: 100%;
    justify-content: start;
  }

  .img-title-food-details {
    flex-direction: column;
  }
  .meal-info-status-container {
    width: auto;
  }
  .meal-details-section {
    width: auto;
    margin-right: 0;
  }
}

.highlight-text {
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .discover-meal-card {
    margin-right: 0 !important;
    align-items: center;
    justify-content: center;
  }
  .discover-all-meal-cards-container {
    justify-content: center;
  }
}

@media screen and (max-width: 1180px) {
  .main-container-statistics {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .right-statistics-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .main-category-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .daily-meals-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: wrap;
  }
  .main-container1 {
    display: flex !important;
    flex-direction: column-reverse;
    align-items: center !important;
    justify-content: center !important;
  }
  .main-page-body-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 490px) {
  .single-chosen-meal-main {
    margin-left: 0.5rem;
  }
  .main-card-left-container {
    margin-right: 0;
  }
  .footer-bottom-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .footer-info-all-container {
    margin-bottom: 50px;
    margin-top: 50px;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: auto;
  }
  .main-card-right-container {
    width: auto;
  }
  .main-card-left-container {
    width: 100%;
  }
}

.desc-text {
  font-size: 12px;
  text-align: center;
}

.padding-info {
  padding: 5rem;
}

.mandatory-star {
  display: flex;
  justify-content: flex-end;
  color: red;
  align-items: flex-end;
}
